/*#dropdown_country, #dropdown_position, #dropdown_region{*/
/*    width: 100%;*/
/*}*/
.dropdown_element{
    width: auto;
    padding: 0.5em 0;
    margin: 0.4em 0;
    font-size: 14px;
    cursor: pointer;
}

.dropdown_element:hover{
    background: #E6E9ED;
}

.dropdown_element:focus{
    background: #F5F7FA !important;
}
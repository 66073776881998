@font-face {
    font-family: 'PT Sans Caption';
    src: url('PTSans-Caption.woff2') format('woff2'),
    url('PTSans-Caption.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans Caption Bold';
    src: url('PTSans-CaptionBold.woff2') format('woff2'),
    url('PTSans-CaptionBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


body{
    font-family: 'PT Sans Caption Bold', sans-serif;
    margin: 0;
    padding: 0;
    background: #F5F7FA;
}

/**/
#image_header{
    background: no-repeat url("../img/background_clips_2.png");
    background-size: 100% 101px;
    height: 100px;
    /*width: 460px;*/
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
}

#clip{
    background: no-repeat url("../img/clip_backgroup.png");
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
}

#astronaut{
    background: no-repeat url("../img/Amstrong.png");
    /*background-size: contain;*/
    background-size: 100% 101px;
    width: 50%;
    max-width: 132px;
    height: 100%;
}

#black_hole{
    background: no-repeat url("../img/blackhole.png");
    background-size: contain;
    width: 50%;
    max-width: 132px;
    /*transform: scaleX(-1);*/
    height: 100%;

    transition: 1s;
}

#image_header:hover #black_hole{
    transform: scale(1.1); /* Увеличиваем масштаб */
}


/**/

#header_img{
    width: 280px;
}

#right_open_menu_id{
    display: none;
}

.dropdown_div{
    position: relative;
    width: 100%;
}

.dropdown_col_6{
    width: 100%;
    margin: 0;
    display: inline;
    background: #E6E9ED;
    position: absolute;
    z-index: 999;
    margin-left: -49%;
}

.dropdown_col_4{
    width: 100%;
    margin: 0;
    display: inline;
    background: #E6E9ED;
    position: absolute;
    z-index: 999;
    border: 1px solid #434A54;
    border-radius: 5px;
    margin-left: -49.5%;
}

.dropdown_col_12{
    width: 100%;
    margin: 0;
    display: none;
    background: #E6E9ED;
    position: absolute;
    z-index: 999;
    margin-left: -50%;
    border: 1px solid #434A54;
    border-radius: 5px;
}

.buttons{
    padding: 0;
    margin: 0 20px;
    border-radius: 5px;
    border: solid 0 transparent;
    width: 200px;
    height: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #FFF;
}

.max_200{
    max-width: 200px;
    width: 100%;
}

.right_button{
    margin-left: auto;
    margin-right: 20px;
}

.buttons_div{
    margin: 0;
    padding: 30px 0 0 0;
}

#cancel_button{
    background: #FFCE54;
}

.container{
    max-width: 1280px;
    margin: 0 auto;
}

.header_fixed{
    z-index: 9;
    background: #F5F7FA;
    position: fixed;
    max-width: 1280px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.header_text{
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    color: #434A54;
}

.header_small_text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-indent: 20px;
    margin: 2px 0 0 0;
    color: rgba(67, 74, 84, 0.5);
}

.header{
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
}
.header_menu_select{
    cursor: pointer;
    position: relative;
}

.header_menu{
    display: flex;
    justify-content: space-between;
}

.user_move{
    display: flex;
}

.user_data{
    padding: 0 12px 0 0;
}

.main_profile{
    display: flex;
    align-items: center;
}

.header_menu p:first-child{
    padding: 0 70px 0 0;
}

#user_fio{
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    color: #434A54;
    margin:  6px 0 0 0;
    padding: 0 0 0 0;
    text-align: right;
}

.user_image{
    margin: auto 0;
    width: 80px;
    height: 80px;
}

.avatar_header{
    width: 80px;
    height: 80px;
}

.select_bar{
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 14px;
}

.left_select_bar{
    justify-content: flex-end;
}

.left_select_bar p:first-child{
    padding-right: 1em;
}

/*.select_bar p:nth-child(2n){*/
    /*padding: 0 100px 0 100px;*/
/*}*/


.main{
    min-height: calc(100vh - 101px);
    text-align: center;
    max-width: 850px;
    margin: 0 auto;
    border-left: 1px solid rgba(67, 74, 84, 0.2);
    border-right: 1px solid rgba(67, 74, 84, 0.2);
}

.main_without_border{
    border-left: none;
    border-right: none;
}

.title_greetings{
    margin: 0;
    padding: 50px 0 0 0;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;

    letter-spacing: 0.25em;
    text-transform: uppercase;

    color: #434A54;
}

.modal_invite_p{
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
}

.modal_invite_span{
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 70px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000000;
}

.greetings{
    margin: 0;
    padding: 155px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;

    color: #000000;
}

.link{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #4FC1E9;
    text-decoration: none;
    padding-top: 10px;
}

.link_pass{
    margin: 0 20px 0 0;
    padding: 40px 0 0 0;
}

#auth_button{
    background: #4FC1E9;
    width: 574px;
    margin: 0 0 0 0;
}

.step_reg{
    /*position: absolute;*/
    /*top: 95%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/

    /*display: flex;*/
    /*flex-direction: column;*/
    /*margin: 0 auto;*/

    position: sticky;
    top: 95%;
}

.input{
    padding: 0;
    margin: 30px 6px 0 6px;
    width: 276px;
    height: 60px;
    background: #E6E9ED;
    border-radius: 5px;
    border: 0 solid transparent;
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
}

.input_test{
    width: 100%;
    height: 60px;
    background: #E6E9ED;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
    max-height: 58px;
    padding: 0;
}

.input_test_change{
    width: 100%;
    height: 58px;
    background: #F5F7FA;
    border-radius: 5px;
    border: 1px solid rgba(67,74,84, .2);
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
    max-height: 58px;
    padding: 0;
}

.max_input{
    width: 574px;
}

.max_input:nth-child(2n){
    width: 574px;
    margin: 10px 0 0 0;
}

.password_input{
    background: url("../img/visibilityPass.png") no-repeat #E6E9ED center;
    background-position-x: 95%;
    background-size: auto 50%;
}

.form{
    margin: 30px 0 0 0;
}

.input_small{
    margin: 0 2px;
}

.input_middle{
    width: 418px;
    margin: 10px 2px;
}

.dropdown_small{
    margin: 2px;
}

.dropdown_big{
    margin: 10px 0;
    padding: 0;
    width: 846px;
}

.dropdown_middle{
    margin: 10px 0 0 0;
    padding: 0;
    width: 574px;
}

.button_big{
    width: 846px;
    margin: 10px 0;
}

.label_text{
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #434A54;
}

.password_move{
    display: flex;
    justify-content: space-between;
    margin: 0 200px;
}

.small_text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin: 30px 0 20px 0;
    color: rgba(51, 51, 51, 0.5);

    border-radius: 5px;
}

.blue_text{
    color: #4FC1E9;
    text-decoration: none;
}

.blue_text:hover{
    text-decoration: underline;
}

.grey_text{
    color: #434A54;
    text-decoration: none;
}

.grey_text:hover{
    text-decoration: underline;
}

.avatar{
    width: 200px;
    min-width: 180px;
    height: 200px;
    border: 1px solid rgba(67, 74, 84, 0.2);
    border-radius: 5px;
    /*margin: 0 5px 0 5px;*/
    position: relative;
    overflow: hidden;
}

.avatar:hover .avatar_hover{
    display: flex;
}

.avatar_hover{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(67, 74, 84, 0.5);
    border-radius: 5px;
    display: none;
}

.avatar_hover > label{
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.left_header{
    position: fixed;
    width: 215px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #1D1D39;
}

.right_header{
    position: fixed;
    width: 215px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #1D1D39;
}

.left_header p{
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    padding: 10px 0 10px 0;
    margin: 0;
}

.form_group{
    /*display: flex;*/
    /*flex: 0 0 auto;*/
    /*flex-flow: row wrap;*/
    /*align-items: center;*/
    /*margin-bottom: 0;*/
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.form_row{
    /*width: 100%;*/
    /*display: flex;*/
    display: flex;
    flex-wrap: wrap;

    width: 100%;

}

.row {
    display: flex;
    flex-wrap: wrap;
}

/*.form-row{*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -ms-flex-wrap: wrap;*/
/*    flex-wrap: wrap;*/
/*    margin-right: -5px;*/
/*    margin-left: -5px;*/

/*}*/

/*.form_row > .col,*/
/*.form_row > [class*="col-"] {*/
/*    padding-right: 5px;*/
/*    padding-left: 5px;*/
/*}*/

.ml-5{
    margin-left: 5px;
}

.mb-5{
    margin-bottom: 5px;
}

.mb-15{
    margin-bottom: 15px;
}

.mr-5{
    margin-right: 5px;
}

.mt-5{
    margin-top: 5px;
}

.mb-10{
    margin-bottom: 10px;
}

.mt-10{
    margin-top: 10px;
}

.mr-20{
    margin-right: 20px;
}

.mr-15{
    margin-right: 15px;
}

.ml-15{
    margin-left: 15px;
}

.ml-20{
    margin-left: 20px;
}

.table{
    width: 100%;
    border-spacing: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-indent: 20px;
    color: #FFFFFF;
}

.table_header{
    width: 100%;
    background: none;
    height: 60px;
    text-align: left;
}

.table_header th{
    background: #4FC1E9;
}

.test_col{
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    border-top-left-radius: 5px;
    background: red;
}

.table_header th:first-child{
    border-radius: 5px 0 0 0;
}

.table_header th:last-child{
    border-radius: 0 5px 0 0;
}

.table_main{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-indent: 20px;
    /*color: rgba(67, 74, 84, 0.2);*/
    color: black;
    text-align: left;
}

.table_main:hover{
    background: #E6E9ED;
    color: black;
    cursor: pointer;
}

.table_main_open{
    text-align: left;
    background: #E6E9ED;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-indent: 20px;
    color: #434A54;
}

.table_main td{
    height: 60px;
}
.table_main_open td{
    height: 60px;
}

.pt-100{
    padding-top: 100px;
}

.pt-15{
    padding-top: 15px;
}

.centered_image_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered_image_text_under {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text_title_olymp{
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 5px;
}

.text_under_olymp{
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border-radius: 5px;
}

@media (max-width: 1200px) {
    .container{
        max-width: 1000px;
        margin: 0 auto;
    }

    .header_fixed{
        max-width: 1000px;
    }
}

@media (max-width: 992px) {
    .container{
        max-width: 990px;
        margin: 0 auto;
    }

    .header_fixed{
        max-width: 990px;
    }

    .select_bar p:nth-child(2n){
        padding: 0 50px 0 50px;
    }

}

@media (max-width: 767px) {
    .user_image{
        width: 32px;
        height: 32px;
    }

    .avatar_header{
        width: 32px;
        height: 32px;
    }

    .user_data{
        display: none;
    }

    .select_bar{
        display: none;
    }

    .left_header{
        display: none;
    }

    .step_reg{
        display: none;
    }

    .pt-sm-100{
        padding-top: 100px;
    }

    .container{
        /*max-width: 570px;*/
        width: auto;
        margin: 0 20%;
        /*margin: 0 auto;*/
    }

    .header_fixed{
        width: auto;
        /*margin: 0 20%;*/
        margin: 0;
    }

    .sm_button{
        width: 100%;
        margin: 0 auto;
    }

    .header{
        height: 60px;
        /*margin: 0 16px;*/
    }

    .title_greetings{
        padding-top: 12px;
        font-size: 10px;
    }

    .greetings{
        margin: 0 10px;
        padding-top: 20px;
        font-size: 24px;
    }

    .mobile_greetings{
        padding-top: 60px;
    }

    .password_move{
        display: flex;
        justify-content: space-around;
        margin: 0;
    }

    .link{
        margin: 0;
        padding-bottom: 0;
    }

    .link_pass{
        margin: 0;
    }

    .ml-sm-60{
        margin-left: 60px !important;
    }

    .mr-sm-60{
        margin-right: 60px !important;
    }

    .mb-sm-15{
        margin-bottom: 15px;
    }

    #header_img{
        width: 108px;
    }

    .main{
        min-height: calc(100vh - 101px);
        text-align: center;
        max-width: none;
        margin: 0 auto;
        border-left: 0 solid transparent;
        border-right: 0 solid transparent;
    }
}

@media (max-width: 568px) {

    /*.container{*/
    /*    max-width: none;*/
    /*}*/

    /*.main{*/
    /*    height: calc(100vh - 101px);*/
    /*    text-align: center;*/
    /*    max-width: none;*/
    /*    margin: 0 auto;*/
    /*    border-left: none;*/
    /*    border-right: none;*/
    /*}*/

    .step_reg{
        display: none;
    }

    .pt-sm-100{
        padding-top: 100px;
    }

    .container{
        /*max-width: 320px;*/
        width: auto;
        /*margin: 0 auto;*/
        margin: 0 10%;
    }

    .header_fixed{
        width: auto;
        /*margin: 0 10%;*/
        margin: 0;
    }

    .sm_button{
        width: 100%;
        margin: 0 auto;
    }

    .header{
        height: 60px;
        /*margin: 0 16px;*/
    }

    .title_greetings{
        padding-top: 12px;
    }

    .greetings{
        margin: 0 10px;
        padding-top: 20px;
    }

    .mobile_greetings{
        padding-top: 60px;
    }

    .password_move{
        display: flex;
        justify-content: space-around;
        margin: 0;
    }

    .link{
        margin: 0;
        padding-bottom: 0;
    }

    .link_pass{
        margin: 0;
    }

    .ml-sm-60{
        margin-left: 60px !important;
    }

    .mr-sm-60{
        margin-right: 60px !important;
    }

    .mb-sm-15{
        margin-bottom: 15px;
    }

    #header_img{
        width: 108px;
    }

    .main{
        /*height: calc(100vh - 101px);*/
        height: auto;
        min-height: auto;
        text-align: center;
        max-width: none;
        margin: 0 auto;
        border-left: 0 solid transparent;
        border-right: 0 solid transparent;
    }
}

@media (min-width: 100px) {

    .mt-sm-5{
        margin-top: 5px;
    }

    .mb-sm-5{
        margin-bottom: 5px;
    }

    .ml-20{
        margin-left: 0;
    }

    .mr-20{
        margin-right: 0;
    }

    .mr-15{
        margin-right: 0;
    }

    .ml-15{
        margin-left: 0;
    }

    .ml-5{
        margin-left: 5px;
    }

    .mr-5{
        margin-right: 5px;
    }

    .image_div{
        flex-direction: column;
    }

    .avatar{
        margin: 0 auto;
    }

    .pt-30{
        padding: 15px 0 0 0;
    }

    .pt-60{
        padding: 30px 0 0 0;
    }

    .acc_data{
        padding-top: 20px;
    }

    .load_image{
        padding: 0;
    }

    .content_margin{
        margin-top: 25px;
    }

    .ml-md-5{
        margin-left: 0;
    }

    .mr-md-5{
        margin-right: 0;
    }

    .sm-button-child{
        margin: 5px;
    }

    .child_margin{
        margin: 24px 0 0 0;
        padding: 0;
    }

    .table_header{
        text-indent: 0;
        text-align: center;
    }

    .table_main{
        text-indent: 0;
        text-align: center;
    }

    .text_title_olymp{
        font-size: 24px;
        font-weight: normal;
        margin: 0;
        padding: 0;
    }

    .text_under_olymp{
        font-size: 12px;
        margin: 0 0 10px 0;
        padding: 0;
    }

    .button_start{
        display: flex;
        justify-content: space-around;

    }

    .max_200_left{
        text-decoration: none;
        color: white;
        padding: 0;
        margin: 0 auto;
        width: 100%;
    }

    .max_200_right{
        text-decoration: none;
        color: white;
        padding: 0;
        margin: 0 auto;
        width: 100%;
    }

    .mb-sm-10{
        margin-bottom: 10px;
    }

    .ml-sm-10{
        margin-left: 10px;
    }

    .mr-sm-10{
        margin-right: 10px;
    }

    .link_flex{
        display: flex;
        justify-content: space-around;
    }

    .dropdown_menu{
        position: absolute;
        width: 100%;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;

        color: #1D1D39;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        z-index: 500;

        border-top: 4px solid transparent;
    }

    .dropdown_menu div{
        margin: 0;
        padding: 5px 12px 5px 0;
        border-bottom: 1px solid rgba(67, 74, 84, 0.2);
        max-width: 244px;
        min-width: 200px;
        background: #E6E9ED;
    }

    .dropdown_menu div p{
        margin: 0;
        padding: 0;
    }

    .right_menu{
        display: flex;
    }

    .select_bar{
        display: none;
    }

    .auth_reg{
        display: flex;
        font-size: 9px;
    }

    .auth_reg p{
        padding: 0 1em !important;
        margin: 0;
    }

    .right_open_menu{
        position: fixed;
        left: auto;
        right: 0;
        background: #E6E9ED;
        max-width: 250px;
        min-width: 240px;
        text-align: right;
        direction: rtl;
        text-indent: 10px;
        height: calc(100% - 61px);
        z-index: 999;


        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }

    .right_open_menu p{
        border-bottom: 1px solid rgba(67, 74, 84, 0.2);
        padding: 10px 0;
        margin: 0;
    }

    .pt_header{
        padding-top: 61px;
    }

    .mt_header{
        margin-top: 61px;
    }

    .header_img{
        height: 61px;
        width: auto;
    }

    #simple_modal_message p{
        text-align: center;
    }

    #simple_modal_message{
        min-width: 250px;
    }

    #modal_message{
        min-width: 250px;
    }

    #modal_message p{
        text-align: center;
    }

    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        padding: 0;
        position: absolute;
        z-index: 1;

        border: 1px solid #434A54;
        box-sizing: border-box;
        border-radius: 5px;
        background: #F5F7FA;
        color: #000000;

        font-size: 12px;
        text-align: center;

        width: 200px;
        bottom: 120%;
        left: 50%;
        margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .sm_none{
        display: none;
    }

    .sm_none_block{
        display: none;
    }


    .sm_none_flex{
        display: none;
    }

    .md_none{
        display: inline;
    }

    .md_none_block{
        display: block;
    }

    .md_none_flex{
        display: flex;
    }

    #opacity_panel{
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        opacity: 80%;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 49;
    }

    #opacity_panel_two{
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        opacity: 80%;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 49;
    }

    .text_modal_top{
        font-size: 24px;
    }

    .text_modal_under{
        font-size: 14px;
    }

    .invite_mobile{
        display: inline;
    }

    .invite_comp{
        display: none;
    }

    #image_header{
        display: none;
    }

    .question{
        bottom: 1%;
        right: 2%;
        width: 40px;
        height: 40px;
    }

    .table{
        padding-right: 0;
    }

    .loader{
        left: 45%;
    }

    .pl-5{
        padding-left: 5px;
    }

    .pr-5{
        padding-right: 5px;
    }

    .ml_account-15{
        margin-left: 0;
    }

    .buttons_crop > button:nth-child(2){
        margin-top: 15px !important;
    }

    .crop_modal > h1{
        font-size: 24px;
    }

    .crop_modal{
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto;
    }

    .footer{
        flex-direction: column;
    }

    .footer_contact_data{
        display: flex;
        flex-direction: column;
    }

    .footer_contact_data > a:last-of-type{
        padding: 0;
    }

    .footer_img{
        display: flex;
        width: 100%;
    }

    .footer_img > div{
        margin: 0 auto;
    }

    .footer p{
        text-align: center;
        margin: 10px 0;
    }

    .footer a:first-of-type{
        margin-top: 10px;
    }

    .footer a{
        text-align: center;
        text-decoration: none;
        color: black;
        margin: 0 0 10px 0;
        padding: 0;
    }

    .footer_right, .footer_left{
        margin: 0;
    }

    .main_gif_margin{
        margin: 20px 0;
    }

    .col-sm-1 {
        position: relative;
        width: 100%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        position: relative;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-sm-3 {
        position: relative;
        width: 100%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        position: relative;
        width: 100%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        position: relative;
        width: 100%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        position: relative;
        width: 100%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        position: relative;
        width: 100%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-9 {
        position: relative;
        width: 100%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        position: relative;
        width: 100%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        position: relative;
        width: 100%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-sm-12 {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .account_wrap{
        flex-wrap: wrap;
    }

    .account_shrink{
        flex-shrink: 0;
    }
}

@media (min-width: 768px) {

    .auth_reg{
        font-size: 14px;
    }

    .table_header{
        text-indent: 20px;
        text-align: left;
    }

    .table_main{
        text-indent: 20px;
        text-align: left;
    }

    .child_margin{
        margin: 90px 0 0 0;
        padding: 0;
    }

    .mt-sm-5{
        margin-top: 0;
    }

    .mb-sm-5{
        margin-bottom: 0;
    }

    .mr-20{
        margin-right: 20px;
    }

    .mr-15{
        margin-right: 15px;
    }

    .ml-15{
        margin-left: 15px;
    }

    .ml-20{
        margin-left: 20px;
    }

    .left_header{
        display: none;
    }

    .image_div{
        flex-direction: column;
    }

    .mt_215{
        /*margin: 0 auto;*/
        margin: 0 3%;
    }

    .main{
        border: none;
        max-width: 950px;
    }

    .acc_data{
        padding-top: 20px;
    }

    .pb-md-10{
        padding-bottom: 10px;
    }

    .ml-md-10{
        margin-left: 10px;
    }

    .mr-md-10{
        margin-right: 10px;
    }

    .mt-md-10{
        margin-top: 10px;
    }

    .ml-md-145{
        margin-left: 145px !important;
    }

    .mr-md-145{
        margin-right: 145px !important;
    }

    .avatar{
        margin: 0 auto;
    }

    .content_margin{
        margin-top: 25px;
    }

    .pt-30{
        padding: 15px 0 0 0;
    }

    .pt-60{
        padding: 30px 0 0 0;
    }

    .load_image{
        padding: 0;
    }

    .text_title_olymp{
        font-size: 36px;
        margin: 0;
        padding: 0;
    }

    .text_under_olymp{
        font-size: 14px;
        margin: 10px 0 0 0;
        padding: 0;
    }

    .button_start{
        display: flex;
        justify-content: flex-end;

    }

    .max_200_left{
        text-decoration: none;
        color: white;
        padding: 0;
        margin: 0 20px 0 0;
        width: 100%;
    }

    .max_200_right{
        text-decoration: none;
        color: white;
        padding: 0;
        margin: 0;
        width: 100%;
    }


    .link_flex{
        display: flex;
        justify-content: space-around;
    }

    #forgot_pass{
        margin-left: 20px;
    }

    .mb-sm-10{
        margin-bottom: 0;
    }

    .ml-sm-10{
        margin-left: 0;
    }

    .mr-sm-10{
        margin-right: 0;
    }

    .ml-md-5{
        margin-left: 5px;
    }

    .mr-md-5{
        margin-right: 5px;
    }

    .mb-md-10{
        margin-bottom: 10px;
    }

    .dropdown_menu{
        position: absolute;
        width: 100%;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;

        color: #1D1D39;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        border: none;
    }

    .dropdown_menu div{
        margin: 0;
        padding: 10px 12px 10px 0;
        border-bottom: 1px solid rgba(67, 74, 84, 0.2);
        width: 80%;
        background: #E6E9ED;
    }

    .dropdown_menu div p{
        margin: 0;
        padding: 0;
    }

    .pt_header{
        padding-top: 101px;
    }

    .mt_header{
        margin-top: 101px;
    }

    .header_img{
        height: 101px;
        width: auto;
    }

    #simple_modal_message{
        min-width: 640px;
        max-width: 640px;
    }

    #modal_message{
        min-width: 640px;
        max-width: 640px;
    }

    .sm_none{
        display: inline;
    }

    .sm_none_block{
        display: block;
    }

    .sm_none_flex{
        display: flex;
    }

    .md_none_flex{
        display: none;
    }

    .md_none{
        display: none;
    }

    .md_none_block{
        display: none;
    }

    .text_modal_top{
        font-size: 36px;
    }

    .text_modal_under{
        font-size: 14px;
    }

    .invite_mobile{
        display: none;
    }

    .invite_comp{
        display: inline;
    }

    #black_hole{
        display: none;
    }

    #image_header{
        display: flex;
    }

    #header_img{
        width: 200px;
    }

    .question{
        bottom: 1%;
        right: 2%;
        width: 60px;
        height: 60px;
    }

    .table{
        padding-right: 40px;
    }

    .loader{
        left: 47%;
    }

    .pl-5{
        padding-left: 0;
    }

    .pr-5{
        padding-right: 0;
    }

    .ml_account-15{
        margin-left: 15px;
    }

    .buttons_crop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .buttons_crop > button:nth-child(2){
        margin-top: 0 !important;
    }

    .crop_modal > h1{
        font-size: 36px;
    }

    .crop_modal{
        position: absolute;
        top: 50% ;
        left: 50%;
        width: 100%;
    }

    .footer{
        flex-direction: row;
    }

    .footer_contact_data{
        display: flex;
        flex-direction: column;
    }

    .footer_contact_data > a:last-of-type{
        padding: 10px 0 20px 0;
    }

    .footer div{
        padding: 10px 5px 0 10px;
    }

    .footer div:first-of-type{
        padding: 10px 5px 0 0;
    }

    .footer div:last-of-type{
        padding: 10px 0 0 10px;
    }

    .footer_img{
        display: flex;
        width: auto;
        padding: 0 !important;
    }

    .footer_img > div{
        margin: 0;
    }

    .footer p{
        text-align: left;
    }

    .footer_contact_data > a{
        color: black;
        text-decoration: none;
        text-align: right;
    }

    .footer_right{
        margin-right: 15px;
    }

    .footer_left{
        margin-left: 15px;
    }

    .main_gif_margin{
        margin: 55px 0;
    }

    .col-md-1 {
        position: relative;
        width: 100%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        position: relative;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-md-3 {
        position: relative;
        width: 100%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        position: relative;
        width: 100%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        position: relative;
        width: 100%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-md-6 {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        position: relative;
        width: 100%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        position: relative;
        width: 100%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-9 {
        position: relative;
        width: 100%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        position: relative;
        width: 100%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        position: relative;
        width: 100%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-md-12 {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {

    .main{
        border-left: 1px solid rgba(67, 74, 84, 0.2);
        border-right: 1px solid rgba(67, 74, 84, 0.2);
    }

    .left_header{
        display: inline;
    }

    .mb-5{
        margin-top: 5px;
    }

    .acc_data{
        padding-top: 20px;
    }

    .pt-30{
        padding: 15px 0 0 0;
    }

    .pt-60{
        padding: 30px 0 0 0;
    }

    .load_image{
        padding: 0;
    }

    .mt_215{
        margin: 0 165px;
    }

    .left_header p{
        width: 165px;
    }

    .image_div{
        flex-direction: column;
    }

    .avatar{
        margin: 0 auto;
    }

    .content_margin{
        margin-top: 25px;
    }

    .dropdown_menu{
        position: absolute;
        width: 100%;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;

        color: #1D1D39;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        z-index: 500;
        border: none;
    }

    .dropdown_menu div{
        margin: 0;
        padding: 10px;
        border-bottom: 1px solid rgba(67, 74, 84, 0.2);
        width: 70%;
        background: transparent;
        max-width: none;
        min-width: auto;
    }

    .dropdown_menu div p{
        margin: 0;
        padding: 0;
    }

    .right_menu{
        display: none;
    }

    .select_bar{
        display: flex;
    }

    .tooltip .tooltiptext {
        width: 300px;
        margin-left: -150px; /* Use half of the width (120/2 = 60), to center the tooltip */
    }

    #opacity_panel{
        display: none !important;
    }

    #opacity_panel_two{
        display: none !important;
    }

    #black_hole{
        display: inline;
    }

    #header_img{
        width: 200px;
    }

    .question{
        bottom: 1%;
        right: 1%;
        width: 60px;
        height: 60px;
    }

    .loader{
        left: 50%;
    }

    .col-lg-1 {
        position: relative;
        width: 100%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        position: relative;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-3 {
        position: relative;
        width: 100%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        position: relative;
        width: 100%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        position: relative;
        width: 100%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-lg-6 {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        position: relative;
        width: 100%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        position: relative;
        width: 100%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-lg-9 {
        position: relative;
        width: 100%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        position: relative;
        width: 100%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        position: relative;
        width: 100%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-lg-12 {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }


}

@media (min-width: 1200px) {

    .mt_215{
        margin: 0 215px;
    }

    .left_header p{
        width: 215px;
    }

    .acc_data{
        padding: 0;
    }

    .pt-30{
        padding: 30px 0 0 0;
    }

    .pt-60{
        padding: 60px 0 0 0;
    }

    .image_div{
        flex-direction: row;
    }

    .avatar{
        margin: 5px 10px 0 20px;
    }

    .content_margin{
        margin-top: 90px;
    }

    .dropdown_menu{
        position: absolute;
        width: 100%;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;

        color: #1D1D39;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        z-index: 500;
        border: none;
    }

    .dropdown_menu div{
        margin: 0;
        padding: 10px;
        border-bottom: 1px solid rgba(67, 74, 84, 0.2);
        max-width: none;
        min-width: auto;
        width: 80%;
        background: transparent;
    }

    .dropdown_menu div p{
        margin: 0;
        padding: 0;
    }

    .question{
        bottom: 1%;
        right: 0.6%;
        width: 60px;
        height: 60px;
    }

    .ml_account-15{
        margin-left: 0;
    }

    .col-xl-1 {
        position: relative;
        width: 100%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xl-2 {
        position: relative;
        width: 100%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xl-3 {
        position: relative;
        width: 100%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        position: relative;
        width: 100%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xl-5 {
        position: relative;
        width: 100%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xl-6 {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        position: relative;
        width: 100%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-xl-8 {
        position: relative;
        width: 100%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xl-9 {
        position: relative;
        width: 100%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        position: relative;
        width: 100%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xl-11 {
        position: relative;
        width: 100%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xl-12 {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .account_wrap{
        flex-wrap: nowrap;
    }

    .account_shrink{
        flex-shrink: 1;
    }
}

.is-invalid{
    border: 1px solid red;
}

.disabled{
    background: rgb(230, 233, 237) !important;
    color: white !important;
    border: 0 solid transparent !important;
}


.button_green{
    cursor: pointer;
    background: #A0D468;
    color: white;
}

.button_green:hover{
    background: #8CC152;
    color: #434A54;
}

.button_green:active{
    background: #A0D468;
    color: #434A54;
    border: 1px solid #434A54;
}


.button_blue{
    cursor: pointer;
    background: #4FC1E9;
    color: white;
    text-transform: uppercase;
}

.button_blue:hover{
    background: #3BAFDA;
    color: #434A54;
    text-transform: uppercase;
}

.button_blue:active{
    background: #4FC1E9;
    color: #434A54;
    border: 1px solid #434A54;
}

.button_green_redesigned{
    cursor: pointer;
    background: #39CC5C;
    border-radius: 8px;
    color: #15202E;
    width: 290px;
    height: 72px;

    font-family: 'PT Sans Caption';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.button_green_redesigned:hover{
    background: #61E280;
}

.button_green_redesigned:active{
    background: #61E280;
}

.button_light_blue_redesigned{
    cursor: pointer;
    background: #6EAFFA;
    border-radius: 8px;
    color: #41361B;
    width: 202px;
    height: 72px;

    font-family: 'PT Sans Caption';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.button_light_blue_redesigned:hover{
    background: #80BBFE;
}

.button_light_blue_redesigned:active{
    background: #80BBFE;
}

.main-page-top-container {
    margin-top: 100px;
    background: #F5F7FA;
}

.main-page-top-left-part {
    margin-top: 70px;
}

.main-page-planet-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page-main-header {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 64px;
    line-height: 96px;

    text-transform: uppercase;

    color: #1B5CBE;
}

.main-page-header-description {
    font-family: 'PT Sans Caption';
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;

    color: #9794B3;
}

.main-page-sub-header {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;

    text-align: center;

    color: #FA7348;
}

.main-page-sub-description {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;

    text-align: center;

    color: #000000;

    padding-left: 18px;
    padding-right: 18px;
}

.main-page-second-header {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;

    padding-left: 22px;
    padding-right: 22px;

    text-align: center;

    color: #1B5CBE;
}

.main-page-goals {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
}

.main-page-goal-block {
    background: #F5F7FA;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    width: 360px;
    height: 268px;
}

.main-page-goal-header {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;

    text-align: center;

    margin-top: 30px;

    color: #000000;
}

.main-page-goal-description {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;

    text-align: center;

    margin-bottom: 40px;

    color: #000000;
}

.main-page-footer {
    justify-content: center;
}

.main-page-character-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page-footer-title {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;

    margin-bottom: 39px;

    color: #615BA3;
}

.main-page-footer-list ul {
    padding-inline-start: 30px;
}

.main-page-footer-list li {
    font-family: 'PT Sans Caption';
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;

    padding-bottom: 28px;

    color: #9794B2;

    list-style-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMiAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjY1NiAyMC41MDFDNC43ODkwMSAyMC41MDEgMC4wMzMyMDMxIDE2LjAyNCAwLjAzMzIwMzEgMTAuNTAxQzAuMDMzMjAzMSA0Ljk3Nzk4IDQuNzg5MDEgMC41MDA5NzcgMTAuNjU2IDAuNTAwOTc3QzE2LjUyMjkgMC41MDA5NzcgMjEuMjc4NyA0Ljk3Nzk4IDIxLjI3ODcgMTAuNTAxQzIxLjI3ODcgMTYuMDI0IDE2LjUyMjkgMjAuNTAxIDEwLjY1NiAyMC41MDFaTTEwLjY1NiAxOC41MDFDMTIuOTA5OCAxOC41MDEgMTUuMDcxNCAxNy42NTgxIDE2LjY2NTEgMTYuMTU3OEMxOC4yNTg4IDE0LjY1NzUgMTkuMTU0MiAxMi42MjI3IDE5LjE1NDIgMTAuNTAxQzE5LjE1NDIgOC4zNzkyNCAxOC4yNTg4IDYuMzQ0NDEgMTYuNjY1MSA0Ljg0NDEyQzE1LjA3MTQgMy4zNDM4MyAxMi45MDk4IDIuNTAwOTggMTAuNjU2IDIuNTAwOThDOC40MDIwOSAyLjUwMDk4IDYuMjQwNTQgMy4zNDM4MyA0LjY0NjgyIDQuODQ0MTJDMy4wNTMxIDYuMzQ0NDEgMi4xNTc3NSA4LjM3OTI0IDIuMTU3NzUgMTAuNTAxQzIuMTU3NzUgMTIuNjIyNyAzLjA1MzEgMTQuNjU3NSA0LjY0NjgyIDE2LjE1NzhDNi4yNDA1NCAxNy42NTgxIDguNDAyMDkgMTguNTAxIDEwLjY1NiAxOC41MDFaTTkuNTk2ODcgMTQuNTAxTDUuMDg5NjMgMTAuMjU4TDYuNTkxNjkgOC44NDM5OEw5LjU5Njg3IDExLjY3M0wxNS42MDUxIDYuMDE1OThMMTcuMTA4MiA3LjQyOTk4TDkuNTk2ODcgMTQuNTAxWiIgZmlsbD0iIzM5Q0M1QyIvPgo8L3N2Zz4K);
    padding-left: 34px;
}

@media (max-width: 1312px) {
    .main-page-top-left-part > div {
        margin-left: 32px;
    }
}

@media (max-width: 1200px) {
    .main-page-planet-image-container > img {
        width: 80%;
    }

    .main-page-goals {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 56px;
    }
}

@media (max-width: 992px) {
    .main-page-main-header {
        font-size: 58px;
        line-height: 80px;
    }
}

@media (max-width: 767px) {
    .main-page-top-left-part {
        margin-top: 0;
    }

    .main-page-top-left-part > div {
/*        max-width: 100%;*/
        margin-left: 0;
    }

    .main-page-planet-image-container {
        display: none;
    }

    .main-page-character-container {
        margin-top: 40px;
    }

    .main-page-goal-block {
        height: auto;
        width: auto;

        width: calc(100% - 120px);
        padding: 40px 35px;
    }
}

@media (max-width: 568px) {
    .main-page-main-header {
        font-size: 40px;
        line-height: 60px;
    }
}

@media (max-width: 400px) {
    .main-page-main-header {
        font-size: 36px;
        line-height: 52px;
    }

    .main-page-second-header {
        font-size: 36px;
    }
}

@media (max-width: 350px) {
    .main-page-character-container > img {
        width: 100%;
    }
}

.button_yellow{
    cursor: pointer;
    background: #FFCE54;
    color: white;
    text-transform: uppercase;
}

.button_yellow:hover{
    background: #F6BB42;
    color: #434A54;
}

.button_yellow:active{
    background: #FFCE54;
    color: #434A54;
    border: 1px solid #434A54;
}

.age_disabled{
    color: #757575;
}

.age_change{
    color: black;
}

.modal_title{
    text-transform: uppercase !important;
}

.elem_menu svg {
    display: none;
}

.elem_menu:hover{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.elem_menu:hover .svg_elem_menu{
    display: inline;
}

.elem_left_menu svg{
    display: none;
    padding-right: 8px;
}

.elem_left_menu:hover{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.elem_left_menu:hover .svg_elem_left_menu{
    display: inline;
}

.test_hover_max {
    transition: 1s; /* Время эффекта */
}

.text_title_olymp{
    transition: 1s;
}

.text_under_olymp{
    transition: 1s;
}

.test_hover_max:hover{
    transform: scale(1.1); /* Увеличиваем масштаб */
}

button{
    font-family: 'PT Sans Caption', sans-serif;
}

#age{
    font-family: 'PT Sans Caption', sans-serif;
}

.link{
    font-family: 'PT Sans Caption', sans-serif;
    cursor: pointer;
}

.elem_left_menu{
    text-indent: 8px;
}

.input_test_change:focus{
    border: 1px solid #4FC1E9;
    outline: none;
}

.terms_and_privacy{
    font-family: 'PT Sans Caption', sans-serif;
}

.top p{
    font-family: 'PT Sans Caption', sans-serif;
}

#dropdown_children_list{
    margin-left: -50%;
}



@-moz-document url-prefix() {
    .dropdown_col_12{
        margin-left: -100.3%;
        margin-top: 60px;
    }

    .dropdown_col_4{
        margin-left: -101%;
        margin-top: 60px;
    }

    .dropdown_col_6{
        margin-left: -101%;
        margin-top: 60px;
    }

    #dropdown_children_list{
        margin-left: -100.2%;
        margin-top: 60px;
    }
}

.question{
    cursor: pointer;
    position: fixed;

    transition: 1s;
}

.question:hover{
    transform: scale(1.2);
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    /*background: transparent;*/
    /*bottom: 0;*/
    /*color: transparent;*/
    /*cursor: pointer;*/
    /*height: auto;*/
    /*left: 0;*/
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 0;*/
    /*width: auto;*/
}

.main{
    padding-bottom: 100px;
}

.date_with_border{

    height: 100%;
}

.date_input_change_first{
    width: 100%;
    height: 58px;
    background: #F5F7FA;
    /*border-radius: 5px;*/
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid rgba(67,74,84, .2);
    border-right: none;
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
    max-height: 58px;
    padding: 0;
}

.date_input_change_last{
    width: 100%;
    height: 58px;
    background: #F5F7FA;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid rgba(67,74,84, .2);
    border-left: none !important;
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
    max-height: 58px;
    padding: 0;
}

.date_input_change_middle{
    width: 100%;
    height: 58px;
    background: #F5F7FA;
    border-radius: 0;
    border: 1px solid rgba(67,74,84, .2);
    font-size: 14px;
    text-indent: 20px;
    font-style: normal;
    font-weight: normal;
    max-height: 58px;
    padding: 0;
}
/*border: 1px solid #4FC1E9;*/
.date_input_change_middle:focus{
    outline: none;
}

.date_input_change_last:focus{
    outline: none;
}

.date_input_change_first:focus{
    outline: none;
}

#date_before{
    display: inline;
}

#date_after{
    display: none;
}

.child_dashboard_container{
    padding: 0 6px;
    width: 132px;
    display: flex;
    margin: 0 auto;
}

.img_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.img_container p{
    margin: 0;
}

.img_container:active{
    background: #E6E9ED;
}

.img_container:hover{
    background: #E6E9ED;
}

.img_container:hover .img_child{
    background: white;
}

.img_child{
    width: 60px;
    height: 60px;
    border-radius: 5px;
}

.child_dashboard_name{

}
/**/

.large-2 {
    float: left;
    width: 100%;
    background: transparent;
}

.large-2::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #E6E9ED;
    border-radius: 5px;
}

.large-2::-webkit-scrollbar {
    width: 10px;
    height: 16px;
}

.large-2::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 64px;
    background-color: #4FC1E9;
}

.splide__list{
    /*display: flex;*/
    /*justify-content: space-around;*/
    /*width: 100%;*/
    /*transform: none !important;*/
}

.splide__pagination{
    display: none !important;
}

.splide__arrow--next{
    position: absolute;
    top: 40%;
}

.splide__arrow--prev{
    position: absolute;
    top: 30%;
}

.splide{
    padding-bottom: 20px;
    padding-top: 20px;
}

.arrow_hover:hover{
    fill: black;
    fill-opacity: 1;
}

.arrow_hover:hover{
    fill: black;
    fill-opacity: 1;
}

.button_transparent{
    background: #F5F7FA;
    border: 1px solid rgba(67, 74, 84, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(29, 29, 57, 0.5);
}

.button_transparent:hover{
    color: rgba(29, 29, 57, 0.5);
    background: #F5F7FA;
    border: 1px solid #434A54;
    box-sizing: border-box;
    border-radius: 5px;
    color: #434A54;
}

.button_transparent:active{
    background: #4FC1E9;
    color: #434A54;
    border: 1px solid #434A54;
}

.button_level_select{
    background: #4FC1E9;
    border: none;
    color: #FFFFFF;
}

.game_text{
    margin: auto 0;
    font-size: 14px;
    font-family: 'PT Sans Caption', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;
}

.line_game{
    width: 2px;
    height: 20px;
    background: rgba(67, 74, 84, 0.2);
    position:relative;
}

.line_game:nth-child(3):after{
    display:block;
    width:5px;
    height:2px;
    content:" ";
    background: rgba(67, 74, 84, 0.2);
    position:absolute;
    left:50%;
    transform: translateX(-100%);
    bottom:-1px;
}

.first_line_game:after {
    display: block;
    width: 5px;
    height: 2px;
    content: " ";
    background: rgba(67, 74, 84, 0.2);
    position: absolute;
    left: 50%;
    transform: translate(-100%, -1000%);
    bottom: -1px;
}

.line_game_time{
    width: 2px;
    height: 54px;
    transform: translateY(16%);
    background: rgba(67, 74, 84, 0.2);
    z-index: -1;


}

.line_game_time_last{
    background: rgba(67, 74, 84, 0.2);
    z-index: -1;
    transform: translateY(23%);
    width: 2px;
    height: 36px;
    position: relative;
}

.line_game_time_last:after{
    position: absolute;
    left: 0;
    top: 100%;
    transform: translateX(-50%);
}

.line_game_time_last:nth-child(1):after{
    content: '0';
}

.line_game_time_last:nth-child(3):after{
    content: '2';
}

.line_game_time_last:nth-child(5):after{
    content: '4';
}

.line_game_time_last:nth-child(7):after{
    content: '6';
}

.line_game_time_last:nth-child(9):after{
    content: '8';
}

.line_game_time_last:nth-child(11):after{
    content: '10';
}

.svg_elem_right_menu{
    transform: rotate(180deg);
    display: none;
}

#user_selector{
    background: #E6E9ED;
}

#user_selector > p{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

#user_selector:hover .svg_elem_right_menu{
    display: inline;
}

.crop_container{
    position: relative;
    width: 100%;
    height: 250px;
    background: #333;
}

.main_relative{
    position: relative;
}

.crop_modal{
    transform: translate(-50%, -50%);
    background: #F5F7FA;
    mix-blend-mode: normal;
    border-radius: 5px;
    overflow: hidden;
    z-index: 51;
    max-width: 540px;
}

.crop_modal > h1{
    margin: 15px 0 0 0;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-family: 'PT Sans Caption', sans-serif;
}

.crop_modal > div{
    margin: 30px 50px 10px 50px;
}

.crop_opacity{
    opacity: 80%;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}

.splide__list{
    display: flex;
    width: 100%;
}

.splide__slide{
    margin: 0 auto;
}

.footer p,a{
    font-family: 'PT Sans Caption', sans-serif;
}

.footer{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(67, 74, 84, 0.2);
}

.dropdown_buttons:focus > div > p{
    background: #E6E9ED;
}


#title_main_text{
    font-size: 14px;
    font-weight: normal;
    font-family: 'PT Sans Caption', sans-serif;
    color: #434A54;
    line-height: 19px;
    text-align: left;
}

.dropdown_img{
    background: no-repeat url("../img/dropdownIcon.png") #E6E9ED center right 10px;
    background-size: auto;
}

.dropdown_img_active{
    background: no-repeat url("../img/dropdownIconActive.png") transparent center right 10px;
    background-size: auto;
}
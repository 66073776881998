.paragraph{
    font-size: 24px;
    text-align: center;
    color: black;
    margin: 10px 0;
    padding: 0;
}

#top{
    font-size: 28px;
    text-align: center;
    color: black;
    margin: 10px 0;
    padding: 0;
}

div ul li{
    color: black;
    list-style-type: none;
}

div h1{
    margin: 0 0 0 1em;
    color: black;
    font-size: 24px;
}

p{
    color: black;
}

#terms{
    margin: 1em 3em;
}

@media (min-width: 100px) {
    #terms{
        margin: 0;
    }
}

@media (min-width: 768px) {
    #terms{
        margin: 1em 3em;
    }
}
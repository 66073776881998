.was-validated .custom-select:valid {
    background-image: none;
}


.was-validated .custom-select:invalid {
    background-image: none;
}

.form-not-image.is-valid {
    background-image: none;
}

.form-not-image.is-invalid {
    background-image: none;
}

.form-not-image-sm.is-valid {
    background-image: none;
}

.form-not-image-sm.is-invalid {
    background-image: none;
}

.form-not-image-lg.is-valid {
    background-image: none;
}

.form-not-image-lg.is-invalid {
    background-image: none;
}
main{
    display: block;
}

.profile_img{
    height: 2rem;
    width: 2rem;
    border-radius: 0.4rem;
}

#layoutSidenav{
    display: flex;
    top: 0;
    left: 0;
    right: 0;
}

#layoutSidenav_nav{
    position: relative;
    z-index: 99999999999;
    display: flex;
}

#layoutSidenav_content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 3.625rem);
}

#accordionSidenav{
    height: 100vh;
}

.dropdown-toggle::after {
    content: none;
}

.dropdown-header{
    background: #00a180;
}

.red_text{
    color: red;
    margin: 0;
}
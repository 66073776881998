.paragraph{
    font-size: 24px;
    text-align: center;
    color: black;
    margin: 10px 0;
    padding: 0;
}

#policy{
    margin: 1em 3em;
}

#top{
    font-size: 28px;
    text-align: center;
    color: black;
    margin: 10px 0;
    padding: 0;
}

p{
    color: black;
}

@media (min-width: 100px) {
    #policy{
        margin: 0;
    }
}

@media (min-width: 768px) {
    #policy{
        margin: 1em 3em;
    }
}

#country, #region, #city {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  background-image: url('https://www.w3schools.com/howto/searchicon.png');
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#country, #region, #city:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content p {
  color: black;
  display: block;
}

.dropdown p:hover {background-color: #ddd;}

.show {display: block;}
.tooltip {
    display:inline-block;
    position:relative;
    /*border-bottom:1px dotted #666;*/
    text-align:left;
}

.tooltip .top {
    min-width:100px;
    min-height: 24px;
    top:-10px;
    /*transform:translate(-50%, -100%);*/
    transform:translateY(-100%);
    padding:4px 20px;
    color:#444444;
    background-color:#F5F7FA;
    font-weight:normal;
    font-size:13px;
    border-radius:5px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border: 1px solid black;
    display:none;
}

.tooltip:hover .top {
    display:block;
}

.tooltip .top i {
    position:absolute;
    top:100%;
    left:10%;
    margin-left:-12px;
    width:24px;
    height:12px;
    overflow:hidden;
}

.tooltip .top i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#F5F7FA;
    border: 1px solid black;
}

.top p{
    margin: -3px 0 0 0;
}